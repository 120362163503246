<template>
  <custom-dialog
    :visible.sync="visible"
    :title="title"
    @close="colseDialog"
    @submit="submit"
    width="45%"
  >
    <div class="editTitle">基本信息</div>

    <div class="edit-main">
      <el-form
        :model="editForm"
        label-width="120px"
        :rules="rules"
        ref="editVersionForm"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="监控IMEI" prop="deviceImei">
              <el-input
                size="small"
                v-model="editForm.deviceImei"
                placeholder="请输入起始设备号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12" v-if="!number==true">
            <el-form-item label="数量" prop="amount">
              <el-input
                size="small"
                v-model="editForm.amount"
                placeholder="请输入数量"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="现场编号" prop="deviceNo"  v-if="number==true">
              <el-input
                size="small"
                v-model="editForm.deviceNo"
                placeholder="请输入现场编号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="监控类型" prop="deviceType">
              <el-select
                class="block-select"
                size="small"
                v-model="editForm.deviceType"
                placeholder="请选择监控类型"
              >
                <el-option
                  v-for="(item, index) in equipTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="终端版本" prop="deviceVersion">
                <el-input
                size="small"
                v-model="editForm.deviceVersion"
                placeholder="请输入终端版本"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="使用状态" prop="deviceState">
              <el-select
                class="block-select"
                size="small"
                v-model="editForm.deviceState"
                placeholder="请选择使用状态"
              >
                <el-option
                  v-for="(item, index) in deviceStateList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="机器版本" prop="generation">
              <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="editForm.generation"
                  placeholder="请选择机器版本"
                >
                  <el-option
                    v-for="item in generationList"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col>
          <!-- <el-col :span="12">
            <el-form-item label="所属项目" prop="projId">
              <el-select
                  class="block-select"
                  size="small"
                  clearable
                  v-model="editForm.projId"
                  placeholder="请选择所属项目"
                >
                  <el-option
                    v-for="item in projectList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
            </el-form-item>
          </el-col> -->

          <el-col :span="24">
            <el-form-item label="备注" prop="deviceNote">
              <el-input
                size="small"
                type="textarea"
                :rows="2"
                v-model="editForm.deviceNote"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </custom-dialog>
</template>

<script>
import CustomDialog from "../../components/common/CustomDialog.vue";
import config from "../../api/config";
import sysManage from "../../api/modules/sysManage";

  import { equipType,deviceStates,generationList } from "../../config/dataStatus";
  import {organizationCascader, phoneReg} from "../../utils/tool";
import organizationTree from "../../api/modules/organizationTree";

export default {
  name: "deviceTermiEdit",
  components: { CustomDialog },
  props: ["visible", "title", "item",'number'],
  data() {
    return {
      editForm: {
      
      },
      rules: {
        deviceImei: [{ required: true, message: "请输入监控IMEI", trigger: "blur" }],
       
        deviceType: [
          { required: true, message: "请选择监控类型", trigger: "change" },
        ],
        deviceVersion: [
          { required: true, message: "请输入终端版本", trigger: "blur" },
        ],
        deviceNo: [
          { required: true, message: "请输入现场编号", trigger: "blur" },
        ],
        deviceState: [
          { required: true, message: "请选择使用状态", trigger: "change" },
        ],
        generation: [
          { required: true, message: "请选择机器版本", trigger: "change" },
        ],
        amount: [
          { required: true, message: "请输入数量", trigger: "blur" },
        ],
      },
      uploadUrl: config.uploadUrl,
      
      equipTypeList:equipType,
      deviceStateList:deviceStates,
      parentList: [],
      copanyListInfo: [],
      projectList:[],
      generationList:generationList
      // equipType: equipType
    };
  },
  watch: {
    item(newVal, oldVal) {
      if (newVal.deviceId) {
        this.editForm = newVal;
        
      } else {
        this.editForm = {
            deviceImei: "",
            deviceType: "",
            deviceVersion: "",
            deviceState: 1,
            orgaIdArr: "",
            generation:6
     
        };
      }
      //   this.resetFields()
    },
  },
  mounted() {
    // this.getCopanyInfo();
    this.getProjectList()
  },
  methods: {
    colseDialog() {
      this.$emit("close");
    },
    getProjectList(){
      sysManage.GetProjectList().then(res=>{
        console.log('项目列表',res);
        res.data.map(item=>{
          this.projectList.push({
            label:item.projName,
            value:item.projId
          })
        })
        
      })
    },
    getCopanyInfo() {
      organizationTree.getOrganizationTree().then((res) => {
        // 递归遍历 生成 {value, label, children}
        let data = organizationCascader(res.data);
        this.copanyListInfo = data;
      });
    },
    cascaderEditChange(val) {
      let valLen = val.length;
      this.editForm.orgaId = val[valLen - 1];
    },
    //获取摸块下拉框
    getModule() {
      sysManage.getModuleList({ moduleType: 0 }).then((res) => {
        console.log("res", res);
        this.parentList = [...res.data];
      });
    },
    submit() {
   
      this.$refs.editVersionForm.validate((valid) => {
        if (valid) {
          if (this.editForm.deviceId) {
            sysManage.editTerminal(this.editForm).then((res) => {
              this.$message({
                message: "编辑成功",
                type: "success",
              });
              this.$emit("done");
            });
          } else {
            sysManage.addBatchTerminal(this.editForm).then((res) => {
              this.$message({
                message: "新增成功",
                type: "success",
              });
              this.$emit("done");
            });
          }
        }
      });
    },
  },
};
</script>

<style scoped lang="less">
.editTitle {
  border: 1px solid #eeeeee;
  border-bottom: 0px solid #eeeeee;
  padding: 10px 0 10px 30px;
  border-radius: 6px 6px 0px 0px;
  font-weight: bold;
}
.edit-main {
  padding: 20px;
  border: 1px solid #eeeeee;
  border-radius: 0px 0px 6px 6px;
}
</style>
