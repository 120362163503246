<template>
  <div class="pageMain">
    <search-head :model="searchForm" @search="search" @reset="reset">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="主机IMEI">
          <el-input
            v-model="searchForm.deviceImei"
            size="small"
            placeholder="主机IMEI"
          ></el-input>
        </el-form-item>
        <el-form-item label="使用状态">
          <el-select
            v-model="searchForm.deviceState"
            size="small"
            placeholder="使用状态"
          >
            <el-option label="使用" value="0"></el-option>
            <el-option label="闲置" value="1"></el-option>
            <el-option label="损坏" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input
            v-model="searchForm.projName"
            size="small"
            placeholder="项目名称"
          ></el-input>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
      :data="tableData"
      :headerCellStyle="headerCellStyle"
      @selectionChange="handleSelectionChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSizeChange="handleSizeChange"
    
      :total="total"
      title="终端备案"
    >
      <template slot="btns">
        <el-button
        type="success"
          size="small"
          @click="addFrom"
          icon="el-icon-plus"
          class="addBtn"
          >新增</el-button
        >
        <el-button
       
          size="small"
          @click="addFrom2"
          icon="el-icon-plus"
          class="btn"
          >批量新增</el-button
        >
      </template>
      <el-table-column type="selection" align="center" width="55">
      </el-table-column>
      <el-table-column prop="deviceState" label="使用状态" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.deviceState == 0" type="success">使用</el-tag>
          <el-tag v-if="scope.row.deviceState == 1" type="warning">闲置</el-tag>
          <el-tag v-if="scope.row.deviceState == 2" type="danger">损坏</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="deviceImei" align="center" label="主机IMEI" show-overflow-tooltip>
      </el-table-column>
      <el-table-column prop="deviceType" align="center" label="监控类型">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceType == 0">塔吊</span>
          <span v-if="scope.row.deviceType == 1">升降机</span>
          <span v-if="scope.row.deviceType == 2">龙门吊</span>
          <span v-if="scope.row.deviceType == 3">架桥机</span>
          <span v-if="scope.row.deviceType == 4">爬模</span>
          <span v-if="scope.row.deviceType == 5">挂篮</span>
          <span v-if="scope.row.deviceType == 8">扬尘</span>
          <span v-if="scope.row.deviceType == 9">喷淋</span>
        </template>
      </el-table-column>
      <el-table-column prop="deviceVersion" align="center" label="终端版本">
      </el-table-column>
      <el-table-column prop="deviceOnline" align="center" label="在线状态">
        <template slot-scope="scope">
          <span v-if="scope.row.deviceOnline == 0">在线</span>
          <span v-if="scope.row.deviceOnline == 1">离线</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="deviceNo"
        align="center"
        label="现场编号"
      >
      </el-table-column>
      <el-table-column prop="generation" align="center" label="机器版本">
      </el-table-column>
      <el-table-column prop="projName" align="center" label="所属项目" show-overflow-tooltip>
      </el-table-column>
      <el-table-column label="操作" align="center" width="100">
        <template slot-scope="scope">
          <span @click="detailItem(scope.row)" class="detail">
            <i class="el-icon-edit"></i>
          </span>
          <span @click="deleteItem(scope.row)" class="detail delete">
            <i class="el-icon-delete"></i>
          </span>
        </template>
      </el-table-column>
    </custom-tables>
    <deviceTermiEdit
      :visible.sync="showDialog"
      @close="colseDialog"
      :title="title"
      @done="doneDialog"
      :item="item"
    >
    </deviceTermiEdit>
    <deviceTermiEditSome
      :visible.sync="showDialog2"
      @close="colseDialog2"
      :title="title"
      @done="doneDialog2"
      :item="item"
      :number="number"
    >
    </deviceTermiEditSome>
  </div>
</template>

<script>
import SearchHead from "../../../components/common/SearchHead";
import CustomTables from "../../../components/common/CustomTables";
import deviceTermiEdit from "../../../components/systemManage/deviceTermiEdit.vue";
import deviceTermiEditSome from "../../../components/systemManage/deviceTermiEditSome.vue";
import sysManage from "../../../api/modules/sysManage";
export default {
  name: "index",
  components: { deviceTermiEdit, CustomTables, SearchHead,deviceTermiEditSome },
  data() {
    return {
      tableData: [],
      searchForm: {
        currPage: 1,
        pageSize: 10,
      },
      total: 0,
      showDialog: false,
      showDialog2: false,
      headerCellStyle: {
        backgroundColor: "#f3f2f7",
        color: "#373628",
      },
      title:'',
      number:false,
      item:{}
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      sysManage.getTerminalListByPage(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    search() {
      console.log("searchForm", this.searchForm);
      this.searchForm.currPage = 1;
      this.searchForm.pageSize = 10;
      this.getList();
    },
    reset() {
      this.searchForm = {
        currPage: 1,
        pageSize: 10,
      };
      this.getList();
    },
    addFrom() {
      this.item={}
      this.showDialog = true;
      this.title='新增终端'
    },
    addFrom2() {
      this.item={}
      this.number=false
      this.showDialog2 = true;
      this.title='批量新增终端'
    },
    colseDialog() {
      this.showDialog = false;
    },
    doneDialog(){
      this.showDialog = false;
      this.getList();
    },
    colseDialog2() {
      this.showDialog2 = false;
    },
    doneDialog2(){
      this.showDialog2 = false;
      this.getList();
    },
    submit() {
      this.showDialog = false;
    },
    detailItem(item) {
      //详情
      if(item.generation=='5'||item.generation=='4'){
        this.item=item
      this.showDialog = true;
      this.number=false
      this.title='编辑终端'
      }else if(item.generation=='6'){
        this.item=item
      this.showDialog2 = true;
        this.number=true
      this.title='编辑终端'
      }
     
    },
    deleteItem(item) {
      console.log(item, 'item');
      this.$confirm(`此操作将永久删除设备${item.deviceImei}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //{staffId:[item.staffId]}
        //  return
        sysManage.delTerminal(item.deviceId).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.getList();
        });
      }).catch(() => {
        this.$message({
          type: "info",
          message: "已取消删除",
        });
      })
    },
    handleSelectionChange(val) {
      console.log(val);
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.currPage = pageIndex
      this.getList()
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.currPage = 1
      this.getList()
    },
  },
};
</script>

<style scoped lang="less">
.detail {
  color: #008f4d;
}
.delete {
  margin-left: 10px;
}
.addBtn {
  background-color: #008f4d;
  color: #fff;
}
.btn {
  background-color: #9054e8;
  color: #ffffff;
  // color: #008f4d;
}
.btn:hover {
  background-color: #7714a6;
}
</style>
